import React from 'react'

const SingleComment = ({ commentString }) => {
    const dateUser = commentString.split('(Comments)')[0];
    const dateValue = dateUser.split(' - ')[0] || '';
    const userValue = dateUser.split(' - ')[1] || '';
    const commentValue = commentString.split('(Comments)')[1] || '';
    return (
        <div className='border-b mb-4'>
            <div className='flex justify-between'>
                <span className='font-medium'>{userValue}</span>
                <span>{dateValue}</span>
            </div>
            <div className='italic'>
                {commentValue}
            </div>
        </div>
    )
}

export default SingleComment
