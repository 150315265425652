import { Box, Grid, LinearProgress } from '@mui/material';
import Searchbar from '../components/Searchbar/Searchbar';
import React, { useState, useEffect } from 'react';
import sadArticle from '../static/sad-article.png';
import upImg from '../static/up.png';
import SearchResults from '../components/SearchResults/SearchResults';
import DisplaySavedSearches from '../components/SavedSearches/DisplaySavedSearches';
import { usePrevious } from '@uidotdev/usehooks';
import SupportBar from '../components/SupportBar/SupportBar';
import { useTranslation } from 'react-i18next';
import SearchSnackBar from '../components/SearchSnakBar/SearchSnackBar';
import ButtonsSpeedDial from '../components/ButtonsSpeedDial/ButtonsSpeedDial';
import { TooltipContextProvider } from '../context/TooltipContext';
import ErrorPopUp from '../components/ErrorPopUp/ErrorPopUp';

import { useReducer } from 'react';
import {
  getLocStorageSearchedSearches,
  addLocStorageSearchedSearches,
  getSearchedSearchById,
  removeLocStorageSearchedSearches
} from '../utils/handleLocalStorage';
import { createUniqueID } from '../utils/UniqueId';
import axiosInstance from '../utils/axiosInstance';

function reducer(state, action) {
  if (action.type === 'LOADING') {
    return {
      ...state,
      isLoading: action.isLoading
    };
  } else if (action.type === 'EMPTY') {
    return {
      ...state,
      isEmpty: true
    };
  } else {
    return state;
  }
}

const Support = (props) => {
  const { userInfo } = props;
  const { t } = useTranslation();
  let [scrollYpage, setScrollYpage] = useState(0);
  let [showGoToTopButton, setShowGoToTopButton] = useState(false);
  let [searchTerm, setSearchTerm] = useState('');
  let [savedSarch, setSavedSarch] = useState(false);
  let [searchEvent, setSearchEvent] = useState(null);
  let [searchPrompt, setSearchPrompt] = useState(
    t('ui_spear.support.Banner.How_may_we_help_you')
  );
  let [analyticId, setAnalyticId] = useState('');
  let [searchedQuery, setSearchedQuery] = useState([]);
  let [searchResponse, setSearchResponse] = useState([]);
  let [userSavedQueries, setUserSavedQueries] = useState([]);
  let [userSearchedQueries, setUserSearchedQueries] = useState([]);
  let [showSaveSearch, setShowSaveSearch] = useState(false);
  let [userNTID, setUserNTID] = useState('');
  let [showHomeSearches, setShowHomeSearches] = useState(true);
  let [lastSearchedTerm, setLastSearchedTerm] = useState(null);
  let [openArticleAccordion, setOpenArticleAccordion] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let [searchSize, setSearchSize] = useState(20);
  let [searchPage, setSearchPage] = useState(0);
  let [showRedefineSearch, setShowRedefineSearch] = useState(false);
  let [hasError, setHasError] = useState(false);
  let [causeOfError, setCauseOfError] = useState('');
  let [userEventRole, setUserEventRole] = useState('ITSP');
  let [showArticleFeedback, setShowArticleFeedback] = useState(false);
  let [infoToast, setInfoToast] = useState(false);
  let [toastMessage, setToastMessage] = useState('');
  let [deletedSearchItem, setDeletedSearchItem] = useState([]);
  let [deletedSearchType, setDeletedSearchType] = useState('');
  let [openSnackBar, setOpenSnackBar] = useState(false);
  let [isShowingOriginalUsrLngArt, setIsShowingOriginalUsrLngArt] =
    useState(true);
  let [userProfileLanguage, setUserProfileLanguage] = useState('');
  let [userLocaleCode, setUserLocaleCode] = useState(
    process.env.REACT_APP_DEFAULT_LANGUAGE
  );
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitError, setSubmitError] = useState(null);
  const [errorType, setErrorType] = useState('');
  const [openChatFromTicket, setOpenChatFromTicket] = useState(false);
  const [openCallFromTicket, setOpenCallFromTicket] = useState(false);
  let [translateKMSLanguage, setTranslateKMSLanguage] = useState(false);
  const [wasUndo, setWasUndo] = useState(false);
  const [stateSavedSeached, dispatchSavedSearched] = useReducer(reducer, {
    isLoading: false,
    isEmpty: false
  });

  let previousSearchTerm = usePrevious(searchTerm);
  let previousSearchEvent = usePrevious(searchEvent);
  let previousSavedSarch = usePrevious(savedSarch);
  let previousIsShowingOriginalUsrLngArt = usePrevious(
    isShowingOriginalUsrLngArt
  );
  const [articleId, setArticleId] = useState('');
  let previousDeletedSearchItem = usePrevious(deletedSearchItem);
  let previousDeletedSearchType = usePrevious(deletedSearchType);
  let previousUserProfileLanguage = usePrevious(userProfileLanguage);

  const letsFindASolution = t('ui_spear.search.Banner.Lets_find_a_solution');

  const [isMounted, setIsMounted] = useState(false);

  const showErrorPopUp = () => {
    setErrorPopUp(!errorPopUp);
  };

  const handleScroll = () => {
    const y = document.getElementById('dash-search-box-id').scrollTop;
    setScrollYpage(y);
    if (y >= 450 && showGoToTopButton === false) {
      setShowGoToTopButton(true);
    } else if (y < 450 && showGoToTopButton === true) {
      setShowGoToTopButton(false);
    }
  };

  function requestUserOptions() {
    getSavedSearched();
    updateUserSearchedQueries();
  }

  function getSavedSearched() {
    const getSavedQueries = `/api/v1/queries/saved`;
    dispatchSavedSearched({
      type: 'LOADING',
      isLoading: true
    });
    axiosInstance
      .get(getSavedQueries, {
        params: {
          ntid: userInfo?.userNTID,
          type: 'RECENT_USER_QUERIES'
        }
      })
      .then((savedQueriesResponse) => {
        if (savedQueriesResponse.data.dataset.length === 0) {
          dispatchSavedSearched({
            type: 'EMPTY',
            isEmpty: true
          });
        } else {
          dispatchSavedSearched({
            type: 'EMPTY',
            isEmpty: false
          });
        }
        setUserSavedQueries(savedQueriesResponse.data.dataset);
      })
      .catch((error) => {
        console.error(`There was an error retrieving saved queries: ${error}`);
      })
      .finally(() => {
        dispatchSavedSearched({
          type: 'LOADING',
          isLoading: false
        });
      });
  }

  const setSavedSearchedQueries = () => {
    const getSavedQueries = `/api/v1/queries/saved`;
    const locStorage = getLocStorageSearchedSearches();
    if (locStorage == null) {
      setUserSearchedQueries([]);
    } else {
      setUserSearchedQueries(locStorage);
    }
    axiosInstance
      .get(getSavedQueries, {
        params: {
          ntid: userInfo?.userNTID,
          type: 'RECENT_USER_QUERIES'
        }
      })
      .then((savedQueriesResponse) => {
        setUserSavedQueries(savedQueriesResponse.data.dataset);
      })
      .catch((error) => {
        console.error(`There was an error retrieving saved queries: ${error}`);
      });
  };

  const updateUserSavedQueries = () => {
    const submitURL = `/api/v1/queries/saved`;
    axiosInstance
      .get(submitURL, {
        params: {
          ntid: userInfo?.userNTID,
          type: 'RECENT_USER_QUERIES'
        }
      })
      .then((response) => {
        setUserSavedQueries(response.data.dataset);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 404
        ) {
          console.error('404' + error);
        } else {
          console.error('Other Error:' + error);
        }
      });
  };

  const updateUserSearchedQueries = () => {
    const locStorageValue = getLocStorageSearchedSearches();
    if (locStorageValue == null) {
      setUserSearchedQueries([]);
    } else {
      setUserSearchedQueries(locStorageValue);
    }
  };

  useEffect(() => {
    setIsMounted(false);
    requestUserOptions();
    getURLParams();
    setIsMounted(true);
  }, []);

  useEffect(() => {
    requestUserOptions();
    getUserProfileLanguage();
    getURLParams();
  }, [userInfo, userLocaleCode]);

  const checkSearchRerender = () => {
    if (userInfo?.userLanguage) {
      setUserProfileLanguage(userInfo?.userLanguage);
    }
  };

  useEffect(() => {
    if (
      previousUserProfileLanguage !== '' &&
      previousUserProfileLanguage !== userProfileLanguage
    ) {
      handleSearchSubmit(searchEvent);
    }
  }, [userProfileLanguage]);

  const getUserProfileLanguage = () => {
    //TODO put correct implementation to get the user Language according to its profile in SN right now is hardcoded
    let userLanguage = userInfo?.userLanguage
      ? userInfo?.userLanguage
      : process.env.REACT_APP_DEFAULT_LANGUAGE;

    const kmsLanguageURL = `/api/v1/articles/availableLanguages`;
    axiosInstance
      .post(kmsLanguageURL, { userProfileLanguage: userLanguage })
      .then((availableLanguage) => {
        if (availableLanguage.status === 200) {
          if (availableLanguage.data.status === 2) {
            setUserLocaleCode(process.env.REACT_APP_DEFAULT_LANGUAGE);
            setTranslateKMSLanguage(false);
          } else {
            setUserLocaleCode(availableLanguage.data.language);
            setTranslateKMSLanguage(true);
            checkSearchRerender();
          }
        } else {
          setUserLocaleCode(process.env.REACT_APP_DEFAULT_LANGUAGE);
          setTranslateKMSLanguage(false);
        }
      });
  };

  const getURLParams = () => {
    let urlParams = new URLSearchParams(window.location.search);
    // If article passed in params, display it
    if (
      urlParams.get('article') != null ||
      urlParams.get('article') != undefined
    ) {
      let articleID = urlParams.get('article');
      setArticleId(articleID);
      getArticleById(articleID);
    } else if (articleId !== '') {
      getArticleById(articleId);
    }
  };

  const getArticleById = (articleID) => {
    // Prepare article url
    const articleURL = `/api/v1/articles/kms/id`;
    // Show loading view
    setLastSearchedTerm(searchTerm);
    setSearchTerm(searchTerm.trim().replace(/\s+/g, ' '));
    setIsLoading(true);

    // Callback once finished, perform network call
    axiosInstance
      .post(articleURL, {
        searchString: articleID,
        userProfileLanguage: isShowingOriginalUsrLngArt
          ? userLocaleCode
          : 'en-US'
      })
      .then((response) => {
        // If everything correct, display article and stop loading stage
        setSearchResponse(response.data);
        setSearchPrompt(letsFindASolution);
        setShowSaveSearch(true);
        setShowHomeSearches(false);
        setIsLoading(false);
        setShowRedefineSearch(
          response.data && response.data.length > 0 && true
        );
        setHasError(false);
        setCauseOfError('');
        setOpenArticleAccordion(true);
        //TODO EVENT DATA onSearchResults(response.data);
      })
      .catch((error) => {
        showErrorPopUp();
        setErrorMessage(error.response.data.message);
        setErrorType('Share article');
        //If error proceed with current search error handling
        //TODO ERROR HANDLING backboneErrorHandler(error, that)
      });
  };

  const handleChangeAutoComplete = (event, newValue) => {
    setSearchTerm(newValue);
    setSearchEvent(event);
  };

  useEffect(() => {
    if (
      previousSearchTerm !== searchTerm &&
      previousSearchEvent !== searchEvent
    ) {
      //Autocomplete search handleSearchSubmit
      handleSearchSubmit(searchEvent);
    }
  }, [searchTerm, searchEvent]);

  const saveSearchedSearch = () => {
    const currSearchedSearch = {
      id: createUniqueID(),
      ntid: userInfo?.userNTID,
      query_description: searchTerm,
      submitted_date: new Date(),
      source: 'searched'
    };
    setSearchedQuery(currSearchedSearch);
    addLocStorageSearchedSearches(currSearchedSearch);
    setSavedSearchedQueries();
  };

  useEffect(() => {
    if (previousIsShowingOriginalUsrLngArt !== isShowingOriginalUsrLngArt) {
      getURLParams();
      handleSearchSubmit(searchEvent);
    }
  }, [isShowingOriginalUsrLngArt]);

  useEffect(() => {
    if (!showHomeSearches) {
      setSearchPrompt(letsFindASolution);
    }
  }, [letsFindASolution]);

  const handleSearchSubmit = (event) => {
    let nativeEvent = null;
    if (event) {
      event.preventDefault();
      nativeEvent = event.nativeEvent;
    }
    if (searchTerm.trim() === '') {
      return;
    }
    document.activeElement.blur();
    const submitURL = `/api/v1/articles`;
    //TODO EVENT DATA const newAnalyticId = this.generateAnalyticId()

    let tempSearchTerm = searchTerm.trim().replace(/\s+/g, ' ');
    let tempLastSearchedTerm = searchTerm;

    setOpenArticleAccordion(false);
    setLastSearchedTerm(searchTerm);
    setSearchTerm(searchTerm.trim().replace(/\s+/g, ' '));
    setIsLoading(true);

    if (tempSearchTerm.length > 0) {
      saveSearchedSearch();
      axiosInstance
        .post(submitURL, {
          searchString: tempSearchTerm,
          searchSize: searchSize,
          searchPage: searchPage,
          contentLevels: [0],
          userProfileLanguage: isShowingOriginalUsrLngArt
            ? userLocaleCode
            : 'en-US'
        })
        .then((response) => {
          setSearchResponse(response.data);
          setSearchPrompt(letsFindASolution);
          setShowSaveSearch(true);
          setShowHomeSearches(false);
          setIsLoading(false);
          setShowRedefineSearch(
            response.data && response.data.length > 0 && true
          );
          setHasError(false);
          setCauseOfError('');
        })
        .catch((error) => {
          console.log(error);
          showErrorPopUp();
          setErrorMessage(error.response.data.message);
          setErrorType('Search article');
        });
    } else {
      setIsLoading(false);
    }
  };

  const handleSearchSubmitTutorial = async (event) => {
    let nativeEvent = null;
    if (event) {
      event.preventDefault();
      nativeEvent = event.nativeEvent;
    }
    document.activeElement.blur();
    const submitURL = `/api/v1/articles`;
    setOpenArticleAccordion(false);
    setLastSearchedTerm(searchTerm);
    setSearchTerm('How to clear cache');
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(submitURL, {
        searchString: 'How to clear cache',
        searchSize: searchSize,
        searchPage: searchPage,
        contentLevels: [0],
        userProfileLanguage: isShowingOriginalUsrLngArt
          ? userLocaleCode
          : 'de-DE'
      });
      setSearchResponse(response.data);
      setSearchPrompt(t('ui_spear.search.Banner.Lets_find_a_solution'));
      setShowSaveSearch(true);
      setShowHomeSearches(false);
      setIsLoading(false);
      setShowRedefineSearch(response.data && response.data.length > 0 && true);
      setHasError(false);
      setCauseOfError('');
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const clearSearchBar = () => {
    setSearchTerm('');
  };

  const handleBackHomePage = () => {
    setSearchPrompt(t('ui_spear.support.Banner.How_may_we_help_you'));
    setSearchTerm('');
    setSearchResponse([]);
    setShowSaveSearch(false);
    setShowHomeSearches(true);
  };

  const handleDeleteSearch = (searchId, type) => {
    let submitURL = '';
    if (type === 'searched') {
      const SearchedToDelete = getSearchedSearchById(searchId);
      removeLocStorageSearchedSearches(searchId);
      setDeletedSearchItem(SearchedToDelete);
      setDeletedSearchType(type);
      setSavedSearchedQueries();
    } else if (type === 'saved') {
      submitURL = `/api/v1/queries/saved`;
      axiosInstance
        .delete(submitURL, {
          data: {
            id: searchId
          }
        })
        .then((response) => {
          setDeletedSearchItem(response.data);
          setDeletedSearchType(type);
          setSavedSearchedQueries();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (previousDeletedSearchItem !== deletedSearchItem) {
      //from handleDeleteSearch
      if (deletedSearchType === 'searched') {
        updateUserSearchedQueries();
      } else if (deletedSearchType === 'saved') {
        updateUserSavedQueries();
        if (searchTerm === deletedSearchItem.query_description) {
          setShowSaveSearch(true);
        }
      }

      if (deletedSearchItem.length === undefined) {
        setOpenSnackBar(true);
      }
    }
  }, [deletedSearchItem]);

  const undoDeleteSearch = () => {
    const saved = 'saved';
    const searched = 'searched';
    if (deletedSearchType === saved) {
      if (deletedSearchItem.query_description !== searchTerm) {
        const submitURLSaved = `/api/v1/queries/saved`;
        axiosInstance
          .post(submitURLSaved, {
            id: deletedSearchItem.id,
            ntid: deletedSearchItem.ntid,
            description: deletedSearchItem.query_description,
            submitted_date: deletedSearchItem.submitted_date
          })
          .then((response) => {
            updateUserSavedQueries();
            setDeletedSearchItem([]);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setWasUndo(true);
      }
    } else if (deletedSearchType === searched) {
      addLocStorageSearchedSearches(deletedSearchItem);
      updateUserSearchedQueries();
    }
    setOpenSnackBar(false);
  };

  const handleInputChange = (value) => {
    setSearchTerm(value);
  };

  const scrollTopevent = () => {
    scrollTop();
  };

  const scrollTop = () => {
    document
      .getElementById('dash-search-box-id')
      .scrollTo({ top: 0, behavior: 'smooth' });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const highlightSearchBar = () => {
    const searchBarText = document.getElementById('autocomplete-search');
    const screenViewContainer = document.getElementById('dash-search-box-id');
    searchBarText.select();
    screenViewContainer.scrollIntoView();
  };

  const toggleInfoToast = (toastMessage) => {
    setInfoToast(!infoToast);
    setToastMessage(toastMessage);
    setTimeout(() => {
      setInfoToast(false);
    }, 3000);
  };

  const handleSavedSearch = (e, savedQuerySearch) => {
    setSearchTerm(savedQuerySearch);
    setSavedSarch(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  useEffect(() => {
    if (
      previousSearchTerm !== searchTerm &&
      previousSavedSarch !== savedSarch
    ) {
      //search handleSavedSearch
      setSavedSarch(false);
      handleSearchSubmit(searchEvent);
    }
  }, [searchTerm, savedSarch]);

  return (
    <TooltipContextProvider>
      <Grid justifyContent="center" alignItems="center">
        {errorPopUp ? (
          <ErrorPopUp
            errorPopUp={errorPopUp}
            errorMessage={errorMessage}
            errorType={errorType}
            togglePopUp={showErrorPopUp}
            submitError={submitError}
            setOpenChatFromTicket={setOpenChatFromTicket}
            setOpenCallFromTicket={setOpenCallFromTicket}
            openCallFromTicket={openCallFromTicket}
            openChatFromTicket={openChatFromTicket}
          ></ErrorPopUp>
        ) : (
          ''
        )}
        <Box id="dash-search-box-id" onScroll={handleScroll}>
          <Grid>
            <Searchbar
              scrollYPage={scrollYpage}
              handleInputChange={handleInputChange}
              handleChangeAutoComplete={handleChangeAutoComplete}
              handleSearchSubmit={handleSearchSubmit}
              clearSearchBar={clearSearchBar}
              searchPrompt={searchPrompt}
              /*analyticId={this.state.analyticId}*/
              searchTerm={searchTerm}
              searchedQuery={searchedQuery}
              searchResponse={searchResponse}
              userSavedQueries={userSavedQueries}
              userSearchedQueries={userSearchedQueries}
              showSaveSearch={showSaveSearch}
              userNTID={userInfo?.userNTID}
              showHomeSearches={showHomeSearches}
              handleBackHomePage={handleBackHomePage}
              updateUserSavedQueries={updateUserSavedQueries}
              handleDeleteSearch={handleDeleteSearch}
              savedSarch={savedSarch}
              wasUndo={wasUndo}
              setWasUndo={setWasUndo}
              lastSearchedTerm={lastSearchedTerm}
              handleSearchSubmitTutorial={handleSearchSubmitTutorial}
            />
          </Grid>

          {hasError ? (
            <Grid container justifyContent="center" alignItems="center">
              <div className="w-4/6">
                {showHomeSearches && (
                  <DisplaySavedSearches
                    userEventRole={userEventRole}
                    userSavedQueries={userSavedQueries}
                    handleSavedSearch={handleSavedSearch}
                    handleDeleteSearch={handleDeleteSearch}
                    stateSavedSeached={stateSavedSeached}
                  />
                )}

                {searchResponse && searchResponse.length > 0 && (
                  <SearchResults
                    userEventRole={userEventRole}
                    searchResponse={searchResponse}
                    searchedQuery={searchedQuery}
                    scrollTop={scrollTop}
                    highlightSearchBar={highlightSearchBar}
                    showArticleFeedback={showArticleFeedback}
                    toggleToastState={toggleInfoToast}
                    openArticleAccordion={openArticleAccordion}
                    userInfo={userInfo}
                    originalUserLanguage={isShowingOriginalUsrLngArt}
                    setOriginalUserLanguage={setIsShowingOriginalUsrLngArt}
                    userLocaleCode={userLocaleCode}
                    translateKMSLanguage={translateKMSLanguage}
                  />
                )}
              </div>
              <Grid item xs={12} className="info-page">
                <Grid
                  container
                  className="grid"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <img id="info-guy" src={sadArticle} alt="sad-article"></img>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <div className="w-full">
              {isLoading && <LinearProgress style={{ color: 'red' }} />}
              <Grid container justifyContent="center" alignItems="center">
                <div className="w-4/6">
                  {showHomeSearches && (
                    <DisplaySavedSearches
                      userEventRole={userEventRole}
                      userSavedQueries={userSavedQueries}
                      handleSavedSearch={handleSavedSearch}
                      handleDeleteSearch={handleDeleteSearch}
                      stateSavedSeached={stateSavedSeached}
                    />
                  )}

                  {searchResponse && searchResponse.length > 0 && (
                    <SearchResults
                      userEventRole={userEventRole}
                      searchResponse={searchResponse}
                      searchedQuery={searchedQuery}
                      scrollTop={scrollTop}
                      highlightSearchBar={highlightSearchBar}
                      showArticleFeedback={showArticleFeedback}
                      toggleToastState={toggleInfoToast}
                      openArticleAccordion={openArticleAccordion}
                      userInfo={userInfo}
                      originalUserLanguage={isShowingOriginalUsrLngArt}
                      setOriginalUserLanguage={setIsShowingOriginalUsrLngArt}
                      userLocaleCode={userLocaleCode}
                      translateKMSLanguage={translateKMSLanguage}
                    />
                  )}
                </div>
              </Grid>
            </div>
          )}
          <Grid container justifyContent="center" alignItems="center">
            <div onClick={scrollTopevent} style={{ display: 'none' }}>
              <img
                src={upImg}
                alt="up"
                className={showGoToTopButton ? 'fadeIn' : 'fadeOut'}
              />
            </div>

            <SearchSnackBar
              openSnackBar={openSnackBar}
              handleCloseSnackBar={handleCloseSnackBar}
              undoDeleteSearch={undoDeleteSearch}
              MsgLabel={t('ui_spear.support.Alert.Search_was_deleted')}
              BtnLabel={t('ui_spear.support.Alert.Search_was_deleted_UNDO')}
            />
          </Grid>
        </Box>

        <Grid container justifyContent="center" alignItems="center">
          <div className="w-4/6">
            <div className="mb-12 mt-[50px]">
              <h3 className="text-[1.75rem] mb-12 font-Bosch_sans_light">
                {t('ui_spear.contactIT.Do_you_need_any_support')}
              </h3>
            </div>
            <SupportBar
              showErrorPopUp={showErrorPopUp}
              setErrorMessage={setErrorMessage}
              setOpenChatFromTicket={setOpenChatFromTicket}
              setOpenCallFromTicket={setOpenCallFromTicket}
              openCallFromTicket={openCallFromTicket}
              openChatFromTicket={openChatFromTicket}
              userInfo={props.userInfo}
              fromSupport={true}
              fromDial={false}
              ticketId=""
            ></SupportBar>
          </div>
        </Grid>

        {isMounted && (
          <ButtonsSpeedDial showScrollButton={showSaveSearch}>
            <SupportBar
              showErrorPopUp={showErrorPopUp}
              setErrorMessage={setErrorMessage}
              setOpenChatFromTicket={setOpenChatFromTicket}
              setOpenCallFromTicket={setOpenCallFromTicket}
              openCallFromTicket={openCallFromTicket}
              openChatFromTicket={openChatFromTicket}
              userInfo={props.userInfo}
              fromSupport={true}
              fromDial={true}
              ticketId=""
            ></SupportBar>
          </ButtonsSpeedDial>
        )}
      </Grid>
    </TooltipContextProvider>
  );
};

export default Support;
